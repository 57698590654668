import ODCLogo from "./images/ODC-logo.jpg";
import neonLogo from "./images/neon-logo.jpg";

const experience = [
  {
    title: "Backend Hackathon",
    logo: ODCLogo,
    company: "Orange Digital Center",
    desc: `During this week at Orange Digital Center, I worked on developing a RESTful API backend
    for Orange Company. The objective of the project was to create a platform for monitoring
    the courses offered by Orange's partners, such as Instant and Amit.`,
    duration: "1 week",
    start: "16/09/2022",
    end: "22/09/2022",
    link: {
      name: "LinkedIn Post",
      link: `https://www.linkedin.com/posts/bassemsaid710_orange-orangedigitalcenter-backend-activity-7035657966984773632-gFKa?utm_source=share&utm_medium=member_desktop`
    }
  },
  {
    title: "Full Stack Developer",
    company: "NEON Software Agency",
    logo: neonLogo,
    desc: `During this week at Orange Digital Center, I worked on developing a RESTful API backend
        for Orange Company. The objective of the project was to create a platform for monitoring
        the courses offered by Orange's partners, such as Instant and Amit.`,
    duration: "",
    start: "1/2024",
    end: "Now",
    link: {
      name: "NEON LinkedIn",
      link: "https://www.linkedin.com/company/neoneg/mycompany/"
    }
  }
];

export default experience;
