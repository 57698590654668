import { Home, About, Skills, Projects, Experience, Contact } from "./index";

const PagesContainer = () => {
  return (
    <>
      <Home />
      <About />
      <Skills />
      <Projects />
      <Experience />
      <Contact />
    </>
  );
};

export default PagesContainer;
