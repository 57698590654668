import javascript from "../components/logos/JavascriptLogo.jsx";
import typescript from "../components/logos/TypeScriptLogo.jsx";
import node from "../components/logos/NodeLogo.jsx";
import react from "../components/logos/ReactLogo.jsx";
import mongo from "../components/logos/MongoLogo.jsx";
import python from "../components/logos/PythonLogo.jsx";
import Docker from "../components/logos/DockerLogo.jsx";
import sass from "../components/logos/SassLogo.jsx";
import git from "../components/logos/GitLogo.jsx";
import figma from "../components/logos/FigmaLogo.jsx";
import express from "../components/logos/ExpressLogo.jsx";
import ruby from "../components/logos/RubyLogo.jsx";
import sql from "../components/logos/SqlLogo.jsx";
import redux from "../components/logos/ReduxLogo.jsx";
import java from "../components/logos/Javalogo.jsx";

const skills = [
  {
    name: "Javascript",
    logo: javascript
  },
  {
    name: "Typescript",
    logo: typescript
  },
  {
    name: "NodeJS",
    logo: node
  },
  {
    name: "ExpressJS",
    logo: express
  },
  {
    name: "ReactJS",
    logo: react
  },
  {
    name: "Redux",
    logo: redux
  },
  {
    name: "Redux-Toolkit",
    logo: redux
  },
  {
    name: "MongoDB",
    logo: mongo
  },
  {
    name: "SQL",
    logo: sql
  },
  {
    name: "Python",
    logo: python
  },
  {
    name: "Java",
    logo: java
  },
  {
    name: "PHP",
    logo: null
  },
  {
    name: "Ruby",
    logo: ruby
  },
  {
    name: "Docker",
    logo: Docker
  },
  {
    name: "SASS",
    logo: sass
  },
  {
    name: "Git",
    logo: git
  },
  {
    name: "RESTful API",
    logo: null
  },
  {
    name: "Agile",
    logo: null
  },
  {
    name: "Linux",
    logo: null
  },
  {
    name: "Figma",
    logo: figma
  },
  {
    name: "Postman",
    logo: null
  },
  {
    name: "Test-Driven Development (TDD)",
    logo: null
  }
];

export default skills;
